/** @hidden */
export var DISABLED = 'e-disabled';
/** @hidden */
export var WRAPTEXT = 'e-wraptext';
/** @hidden */
export var locale = 'spreadsheetLocale';
/** @hidden */
export var dialog = 'dialog';
/** @hidden */
export var actionEvents = 'actionEvents';
/** @hidden */
export var overlay = 'shape';
/** @hidden */
export var fontColor = {
    'Custom': [
        '#ffffff', '#000000', '#e7e6e6', '#44546a', '#4472c4', '#ed7d31', '#a5a5a5', '#ffc000', '#70ad47', '#ff0000',
        '#f2f2f2', '#808080', '#cfcdcd', '#d5dce4', '#d9e2f3', '#fbe4d5', '#ededed', '#fff2cc', '#e2efd9', '#ffcccc',
        '#d9d9d9', '#595959', '#aeaaaa', '#acb9ca', '#b4c6e7', '#f7caac', '#dbdbdb', '#ffe599', '#c5e0b3', '#ff8080',
        '#bfbfbf', '#404040', '#747070', '#8496b0', '#8eaadb', '#f4b083', '#c9c9c9', '#ffd966', '#a8d08d', '#ff3333',
        '#a6a6a6', '#262626', '#3b3838', '#323e4f', '#2f5496', '#c45911', '#7b7b7b', '#bf8f00', '#538135', '#b30000',
        '#7f7f7f', '#0d0d0d', '#161616', '#212934', '#1f3763', '#823b0b', '#525252', '#7f5f00', '#375623', '#660000'
    ]
};
/** @hidden */
export var fillColor = {
    'Custom': [
        '#ffffff', '#000000', '#ffff00', '#00ff00', '#00ffff', '#0000ff', '#ff0000', '#000080', '#800080', '#996633',
        '#f2f2f2', '#808080', '#ffffcc', '#b3ffb3', '#ccffff', '#ccccff', '#ffcccc', '#ccccff', '#ff80ff', '#f2e6d9',
        '#d9d9d9', '#595959', '#ffff80', '#80ff80', '#b3ffff', '#8080ff', '#ff8080', '#8080ff', '#ff00ff', '#dfbf9f',
        '#bfbfbf', '#404040', '#ffff33', '#33ff33', '#33ffff', '#3333ff', '#ff3333', '#0000b3', '#b300b3', '#c68c53',
        '#a6a6a6', '#262626', '#e6e600', '#00b300', '#009999', '#000099', '#b30000', '#000066', '#660066', '#86592d',
        '#7f7f7f', '#0d0d0d', '#999900', '#006600', '#006666', '#000066', '#660000', '#00004d', '#4d004d', '#734d26'
    ]
};
/** @hidden */
export var keyCodes = {
    UP: 38,
    DOWN: 40,
    LEFT: 37,
    RIGHT: 39,
    FIRSTALPHABET: 65,
    LASTALPHABET: 90,
    SPACE: 32,
    BACKSPACE: 8,
    TAB: 9,
    DELETE: 46,
    ENTER: 13,
    ESC: 27
};
/**
 * Default locale text
 *
 * @hidden
 */
export var defaultLocale = {
    FindValue: 'Find value',
    ReplaceValue: 'Replace value',
    FindReplaceTooltip: 'Find & Replace',
    InsertingEmptyValue: 'Reference value is not valid.',
    ReplaceAllEnd: ' matches replaced with ',
    ByRow: 'By Rows',
    ByColumn: 'By Columns',
    MatchCase: 'Match case',
    MatchExactCellElements: 'Match entire cell contents',
    EnterCellAddress: 'Enter cell address',
    FindAndReplace: 'Find and Replace',
    FindNextBtn: 'Find Next',
    FindPreviousBtn: 'Find Previous',
    ReplaceBtn: 'Replace',
    ReplaceAllBtn: 'Replace All',
    GotoHeader: 'Go To',
    Sheet: 'Sheet',
    Workbook: 'Workbook',
    NoElements: 'We couldn\'t find what you were looking for.',
    FindWhat: 'Find what',
    ReplaceWith: 'Replace with',
    Cut: 'Cut',
    Copy: 'Copy',
    Paste: 'Paste',
    PasteSpecial: 'Paste Special',
    Link: 'Link',
    Spreadsheet: 'Spreadsheet',
    AddNote: 'Add Note',
    EditNote: 'Edit Note',
    DeleteNote: 'Delete Note',
    Hyperlink: 'Hyperlink',
    EditHyperlink: 'Edit Hyperlink',
    OpenHyperlink: 'Open Hyperlink',
    RemoveHyperlink: 'Remove Hyperlink',
    InvalidHyperlinkAlert: 'The address of this site is not valid. Check the address and try again.',
    InsertLink: 'Insert Link',
    EditLink: 'Edit Link',
    All: 'All',
    Values: 'Values',
    Formats: 'Formats',
    Bold: 'Bold',
    Font: 'Font',
    FontSize: 'Font Size',
    Italic: 'Italic',
    Underline: 'Underline',
    Strikethrough: 'Strikethrough',
    TextColor: 'Text Color',
    FillColor: 'Fill Color',
    HorizontalAlignment: 'Horizontal Alignment',
    AlignLeft: 'Align Left',
    AlignCenter: 'Center',
    AlignRight: 'Align Right',
    VerticalAlignment: 'Vertical Alignment',
    AlignTop: 'Align Top',
    AlignMiddle: 'Align Middle',
    AlignBottom: 'Align Bottom',
    WrapText: 'Wrap Text',
    MergeCells: 'Merge Cells',
    MergeAll: 'Merge All',
    MergeHorizontally: 'Merge Horizontally',
    MergeVertically: 'Merge Vertically',
    Unmerge: 'Unmerge',
    UnmergeCells: 'Unmerge Cells',
    SelectMergeType: 'Select Merge Type',
    MergeCellsAlert: 'Merging cells will only preserve the top-leftmost(Uppermost) value. Merge anyway?',
    Borders: 'Borders',
    TopBorders: 'Top Borders',
    LeftBorders: 'Left Borders',
    RightBorders: 'Right Borders',
    BottomBorders: 'Bottom Borders',
    AllBorders: 'All Borders',
    HorizontalBorders: 'Horizontal Borders',
    VerticalBorders: 'Vertical Borders',
    OutsideBorders: 'Outside Borders',
    InsideBorders: 'Inside Borders',
    NoBorders: 'No Borders',
    BorderColor: 'Border Color',
    BorderStyle: 'Border Style',
    InsertFunction: 'Insert Function',
    Insert: 'Insert',
    Delete: 'Delete',
    DuplicateSheet: 'Duplicate',
    MoveRight: 'Move Right',
    MoveLeft: 'Move Left',
    Rename: 'Rename',
    Hide: 'Hide',
    FileName: 'File Name',
    PROPER: 'Converts a text to proper case; first letter to uppercase and other letters to lowercase.',
    NameBox: 'Name Box',
    ShowHeaders: 'Show Headers',
    HideHeaders: 'Hide Headers',
    ShowGridLines: 'Show Gridlines',
    ExtendValidation: 'The selection contains some cells without data validation. Do you want to extend validation to these cells?',
    Yes: 'Yes',
    No: 'No',
    HideGridLines: 'Hide Gridlines',
    FreezePanes: 'Freeze Panes',
    FreezeRows: 'Freeze Rows',
    FreezeColumns: 'Freeze Columns',
    UnfreezePanes: 'Unfreeze Panes',
    UnfreezeRows: 'Unfreeze Rows',
    UnfreezeColumns: 'Unfreeze Columns',
    AddSheet: 'Add Sheet',
    ListAllSheets: 'List All Sheets',
    CollapseToolbar: 'Collapse Toolbar',
    ExpandToolbar: 'Expand Toolbar',
    CollapseFormulaBar: 'Collapse Formula Bar',
    ExpandFormulaBar: 'Expand Formula Bar',
    File: 'File',
    Home: 'Home',
    Formulas: 'Formulas',
    View: 'View',
    New: 'New',
    Open: 'Open',
    SaveAs: 'Save As',
    Print: 'Print',
    Update: 'Update',
    ExcelXlsx: 'Microsoft Excel',
    ExcelXls: 'Microsoft Excel 97-2003',
    CSV: 'Comma-separated values',
    FormulaBar: 'Formula Bar',
    Sort: 'Sort',
    SortAscending: 'Ascending',
    SortDescending: 'Descending',
    CustomSort: 'Custom Sort',
    AddColumn: 'Add Column',
    ContainsHeader: 'Data contains header',
    CaseSensitive: 'Case sensitive',
    SortBy: 'Sort by',
    ThenBy: 'Then by',
    SelectAColumn: 'Select a column',
    SortEmptyFieldError: 'All sort criteria must have a column specified. Check the selected sort criteria and try again.',
    SortDuplicateFieldError: '  is being sorted by values more than once. Delete the duplicate sort criteria and try again.',
    SortOutOfRangeError: 'Select a cell or range inside the used range and try again.',
    MultiRangeSortError: 'This can\'t be done on a multiple range selection. Select a single range and try again.',
    SortAndFilter: 'Sort & Filter',
    Filter: 'Filter',
    ClearAllFilter: 'Clear',
    ClearFilterFrom: 'Clear Filter From ',
    ReapplyFilter: 'Reapply',
    FilterCellValue: 'Filter by Value of Selected Cell',
    FilterOutOfRangeError: 'Select a cell or range inside the used range and try again.',
    HideRow: 'Hide Row',
    HideRows: 'Hide Rows',
    UnhideRows: 'Unhide Rows',
    HideColumn: 'Hide Column',
    HideColumns: 'Hide Columns',
    UnhideColumns: 'Unhide Columns',
    InsertRow: 'Insert Row',
    InsertRows: 'Insert Rows',
    Above: 'Above',
    Below: 'Below',
    InsertColumn: 'Insert Column',
    InsertColumns: 'Insert Columns',
    Before: 'Before',
    After: 'After',
    DeleteRow: 'Delete Row',
    DeleteRows: 'Delete Rows',
    DeleteColumn: 'Delete Column',
    DeleteColumns: 'Delete Columns',
    Ok: 'OK',
    Close: 'Close',
    MoreOptions: 'More Options',
    Cancel: 'Cancel',
    Apply: 'Apply',
    MoreColors: 'More Colors',
    StandardColors: 'Standard Colors',
    General: 'General',
    Number: 'Number',
    Currency: 'Currency',
    Accounting: 'Accounting',
    ShortDate: 'Short Date',
    LongDate: 'Long Date',
    Time: 'Time',
    Percentage: 'Percentage',
    Fraction: 'Fraction',
    Scientific: 'Scientific',
    Text: 'Text',
    Custom: 'Custom',
    MobileFormulaBarPlaceHolder: 'Enter value or Formula',
    NumberFormat: 'Number Format',
    PasteAlert: 'You can\'t paste this here, because the copy area and paste area aren\'t in the same size. ' +
        'Please try pasting in a different range.',
    DestroyAlert: 'Are you sure you want to destroy the current workbook without saving and create a new workbook?',
    SheetRenameInvalidAlert: 'Sheet name contains invalid character.',
    SheetRenameEmptyAlert: 'Sheet name cannot be empty.',
    SheetRenameAlreadyExistsAlert: 'Sheet name already exists. Please enter another name.',
    DeleteSheetAlert: 'You can\'t undo deleting sheets, and you might be removing some data. If you don\'t need it, click OK to delete.',
    DeleteSingleLastSheetAlert: 'A Workbook must contain at least one visible worksheet.',
    PickACategory: 'Pick a category',
    Description: 'Description',
    UnsupportedFile: 'Unsupported File',
    DataLimitExceeded: 'File data is too large and it takes more time to process, do you want to continue?',
    FileSizeLimitExceeded: 'File size is too large and it takes more time to process, do you want to continue?',
    InvalidUrl: 'Invalid URL',
    SUM: 'Adds a series of numbers and/or cells.',
    SUMIF: 'Adds the cells based on specified condition.',
    SUMIFS: 'Adds the cells based on specified conditions.',
    ABS: 'Returns the value of a number without its sign.',
    RAND: 'Returns a random number between 0 and 1.',
    RANDBETWEEN: 'Returns a random integer based on specified values.',
    FLOOR: 'Rounds a number down to the nearest multiple of a given factor.',
    CEILING: 'Rounds a number up to the nearest multiple of a given factor.',
    PRODUCT: 'Multiplies a series of numbers and/or cells.',
    INT: 'Returns a number to the nearest integer.',
    ROUNDUP: 'Rounds a number away from zero.',
    SUMPRODUCT: 'Returns sum of the product of given ranges of arrays.',
    SORT: 'Sorts a range of an array',
    T: 'Checks whether a value is text or not and returns the text.',
    EXACT: 'Checks whether a two text strings are exactly same and returns TRUE or FALSE.',
    LEN: 'Returns a number of characters in a given string.',
    MOD: 'Returns a remainder after a number is divided by divisor.',
    ODD: 'Rounds a positive number up and negative number down to the nearest odd integer.',
    PI: 'Returns the value of pi.',
    COUNTBLANK: 'Returns the number of empty cells in a specified range of cells.',
    EVEN: 'Rounds a positive number up and negative number down to the nearest even integer.',
    DECIMAL: 'Converts a text representation of a number in a given base into a decimal number.',
    DEGREES: 'Converts radians to degrees.',
    ADDRESS: 'Returns a cell reference as text, given specified row and column numbers.',
    TIME: 'Converts hours, minutes, seconds to the time formatted text.',
    CHAR: 'Returns the character from the specified number.',
    CODE: 'Returns the numeric code for the first character in a given string.',
    DOLLAR: 'Converts the number to currency formatted text.',
    SMALL: 'Returns the k-th smallest value in a given array.',
    LARGE: 'Returns the k-th largest value in a given array.',
    FACT: 'Returns the factorial of a number.',
    MEDIAN: 'Returns the median of the given set of numbers.',
    EDATE: 'Returns a date with given number of months before or after the specified date.',
    DATEVALUE: 'Converts a date string into date value.',
    HOUR: 'Returns the number of hours in a specified time string.',
    SECOND: 'Returns the number of seconds in a specified time string.',
    MINUTE: 'Returns the number of minutes in a specified time string.',
    NOW: 'Returns the current date and time.',
    MONTH: 'Returns the number of months in a specified date string.',
    TODAY: 'Returns the current date as date value.',
    WEEKDAY: 'Returns the day of the week corresponding to a date.',
    AVERAGE: 'Calculates average for the series of numbers and/or cells excluding text.',
    AVERAGEIF: 'Calculates average for the cells based on specified criterion.',
    AVERAGEIFS: 'Calculates average for the cells based on specified conditions.',
    AVERAGEA: 'Calculates the average for the cells evaluating TRUE as 1, text and FALSE as 0.',
    COUNT: 'Counts the cells that contain numeric values in a range.',
    COUNTIF: 'Counts the cells based on specified condition.',
    COUNTIFS: 'Counts the cells based on specified conditions.',
    COUNTA: 'Counts the cells that contains values in a range.',
    MIN: 'Returns the smallest number of the given arguments.',
    MAX: 'Returns the largest number of the given arguments.',
    DATE: 'Returns the date based on given year, month, and day.',
    DAY: 'Returns the day from the given date.',
    DAYS: 'Returns the number of days between two dates.',
    IF: 'Returns value based on the given expression.',
    IFS: 'Returns value based on the given multiple expressions.',
    CalculateAND: 'Returns TRUE if all the arguments are TRUE, otherwise returns FALSE.',
    CalculateOR: 'Returns TRUE if any of the arguments are TRUE, otherwise returns FALSE.',
    IFERROR: 'Returns value if no error found else it will return specified value.',
    CHOOSE: 'Returns a value from list of values, based on index number.',
    INDEX: 'Returns a value of the cell in a given range based on row and column number.',
    FIND: 'Returns the position of a string within another string, which is case sensitive.',
    TEXT: 'Converts a value to text in specified number format.',
    CONCATENATE: 'Combines two or more strings together.',
    CONCAT: 'Concatenates a list or a range of text strings.',
    SUBTOTAL: 'Returns subtotal for a range using the given function number.',
    RADIANS: 'Converts degrees into radians.',
    MATCH: 'Returns the relative position of a specified value in given range.',
    LN: 'Returns the natural logarithm of a number.',
    INTERCEPT: 'Calculates the point of the Y-intercept line via linear regression.',
    UNIQUE: 'Returns a unique values from a range or array',
    SLOPE: 'Returns the slope of the line from linear regression of the data points.',
    LOOKUP: 'Looks for a value in a one-row or one-column range, then returns a value from the same position in a second one-row or one-column range.',
    HLOOKUP: 'Looks for a value in the top row of the array of values and then returns a value in the same column from a row in the array that you specify.',
    VLOOKUP: 'Looks for a specific value in the first column of a lookup range and returns a corresponding value from a different column within the same row.',
    NOT: 'Returns the inverse of a given logical expression.',
    EOMONTH: 'Returns the last day of the month that is a specified number of months before or after an initially supplied start date.',
    SQRT: 'Returns the square root of a positive number.',
    ROUNDDOWN: 'Rounds a number down, toward zero.',
    RSQ: 'Returns the square of the Pearson product moment correlation coefficient based on data points in known_y\'s and known_x\'s.',
    DefineNameExists: 'This name already exists, try different name.',
    DefineNameInValid: 'The name that you entered is not valid.',
    CircularReference: 'When a formula refers to one or more circular references, this may result in an incorrect calculation.',
    OR: 'OR',
    AND: 'AND',
    CustomFilterDatePlaceHolder: 'Choose a date',
    CustomFilterPlaceHolder: 'Enter the value',
    CustomFilter: 'Custom Filter',
    Between: 'Between',
    DateTimeFilter: 'DateTime Filters',
    Undo: 'Undo',
    Redo: 'Redo',
    DateFilter: 'Date Filters',
    TextFilter: 'Text Filters',
    NumberFilter: 'Number Filters',
    ClearFilter: 'Clear Filter',
    NoResult: 'No Matches Found',
    FilterFalse: 'False',
    FilterTrue: 'True',
    Blanks: 'Blanks',
    SelectAll: 'Select All',
    GreaterThanOrEqual: 'Greater Than Or Equal',
    GreaterThan: 'Greater Than',
    LessThanOrEqual: 'Less Than Or Equal',
    LessThan: 'Less Than',
    NotEqual: 'Not Equal',
    Equal: 'Equal',
    Contains: 'Contains',
    NotContains: 'Does Not Contains',
    EndsWith: 'Ends With',
    NotEndsWith: 'Does Not End With',
    StartsWith: 'Starts With',
    NotStartsWith: 'Does Not Start With',
    IsEmpty: 'Empty',
    IsNotEmpty: 'Not Empty',
    ClearButton: 'Clear',
    FilterButton: 'Filter',
    CancelButton: 'Cancel',
    OKButton: 'OK',
    Search: 'Search',
    ProtectSheet: 'Protect Sheet',
    UnprotectSheet: 'Unprotect Sheet',
    SelectCells: 'Select locked cells',
    SelectUnlockedCells: 'Select unlocked cells',
    FormatCells: 'Format cells',
    FormatRows: 'Format rows',
    FormatColumns: 'Format columns',
    InsertLinks: 'Insert links',
    ProtectContent: 'Protect the contents of locked cells',
    ProtectAllowUser: ' Allow all users of this worksheet to:',
    EditAlert: 'The cell you\'re trying to change is protected. To make change, unprotect the sheet.',
    ReadonlyAlert: 'You are trying to modify a cell that is in read-only mode. To make changes, please disable the read-only status.',
    SearchWithin: 'Search within',
    SearchBy: 'Search by',
    Reference: 'Reference',
    DataValidation: 'Data Validation',
    CellRange: 'Cell Range',
    Allow: 'Allow',
    Data: 'Data',
    Minimum: 'Minimum',
    Maximum: 'Maximum',
    IgnoreBlank: 'Ignore blank',
    WholeNumber: 'Whole Number',
    Decimal: 'Decimal',
    Date: 'Date',
    TextLength: 'Text Length',
    List: 'List',
    NotBetween: 'Not Between',
    EqualTo: 'Equal To',
    NotEqualTo: 'Not Equal To',
    GreaterThanOrEqualTo: 'Greater Than Or Equal To',
    LessThanOrEqualTo: 'Less Than Or Equal To',
    InCellDropDown: 'In-cell-dropdown',
    Sources: 'Sources',
    Value: 'Value',
    Retry: 'Retry',
    DialogError: 'The list source must be a reference to single row or column.',
    MinMaxError: 'The Maximum must be greater than or equal to the Minimum.',
    InvalidNumberError: 'Please enter a valid number.',
    MoreValidation: 'This selection contains more than one validation. \n Erase current settings and continue?',
    FileNameError: 'A file name can\'t contain characters like \\ / : * ? " < > [ ] |',
    ListLengthError: 'The list values allows only upto 256 characters',
    ValidationError: 'This value doesn' + '\'' + 't match the data validation restrictions defined for the cell.',
    EmptyError: 'You must enter a value',
    ClearHighlight: 'Clear Highlight',
    HighlightInvalidData: 'Highlight Invalid Data',
    ClearValidation: 'Clear Validation',
    HighlightCellsRules: 'Highlight Cells Rules',
    CFEqualTo: 'Equal To',
    TextThatContains: 'Text that Contains',
    Save: 'Save',
    EmptyFileName: 'File name cannot be empty.',
    LargeName: 'The name is too long.',
    ADateOccuring: 'A Date Occuring',
    DuplicateValues: 'Duplicate Values',
    TopBottomRules: 'Top/Bottom Rules',
    Directional: 'Directional',
    Shapes: 'Shapes',
    Indicators: 'Indicators',
    Ratings: 'Ratings',
    Top10Items: 'Top 10 Items',
    Top10: 'Top 10',
    Bottom10Items: 'Bottom 10 Items',
    Bottom10: 'Bottom 10',
    AboveAverage: 'Above Average',
    BelowAverage: 'Below Average',
    FormatCellsGreaterThan: 'Format cells that are GREATER THAN:',
    FormatCellsLessThan: 'Format cells that are LESS THAN:',
    FormatCellsBetween: 'Format cells that are BETWEEN:',
    FormatCellsEqualTo: 'Format cells that are EQUAL TO:',
    FormatCellsThatContainTheText: 'Format cells that contain the text:',
    FormatCellsThatContainADateOccurring: 'Format cells that contain a date occurring:',
    FormatCellsDuplicate: 'Format cells that contain:',
    FormatCellsTop: 'Format cells that rank in the TOP:',
    FormatCellsBottom: 'Format cells that rank in the BOTTOM:',
    FormatCellsAbove: 'Format cells that are ABOVE AVERAGE:',
    FormatCellsBelow: 'Format cells that are BELOW AVERAGE:',
    With: 'with',
    DataBars: 'Data Bars',
    ColorScales: 'Color Scales',
    IconSets: 'Icon Sets',
    ClearRules: 'Clear Rules',
    SelectedCells: 'Clear Rules from Selected Cells',
    EntireSheet: 'Clear Rules from Entire Sheet',
    ISNUMBER: 'Returns true when the value parses as a numeric value.',
    ROUND: 'Rounds a number to a specified number of digits.',
    GEOMEAN: 'Returns the geometric mean of an array or range of positive data.',
    POWER: 'Returns the result of a number raised to power',
    LOG: 'Returns the logarithm of a number to the base that you specify.',
    TRUNC: 'Returns the truncated value of a number to a specified number of decimal places.',
    EXP: 'Returns e raised to the power of the given number.',
    Clear: 'Clear',
    ClearContents: 'Clear Contents',
    ClearAll: 'Clear All',
    ClearFormats: 'Clear Formats',
    ClearHyperlinks: 'Clear Hyperlinks',
    LightRedFillWithDarkRedText: 'Light Red Fill with Dark Red Text',
    YellowFillWithDarkYellowText: 'Yellow Fill with Dark Yellow Text',
    GreenFillWithDarkGreenText: 'Green Fill with Dark Green Text',
    RedFill: 'Red Fill',
    RedText: 'Red Text',
    Duplicate: 'Duplicate',
    Unique: 'Unique',
    And: 'and',
    WebPage: 'Web Page',
    ThisDocument: 'This Document',
    DisplayText: 'Display Text',
    Url: 'URL',
    CellReference: 'Cell Reference',
    DefinedNames: 'Defined Names',
    EnterTheTextToDisplay: 'Enter the text to display',
    EnterTheUrl: 'Enter the URL',
    Image: 'Image',
    ConditionalFormatting: 'Conditional Formatting',
    BlueDataBar: 'Blue Data Bar',
    GreenDataBar: 'Green Data Bar',
    RedDataBar: 'Red Data Bar',
    OrangeDataBar: 'Orange Data Bar',
    LightBlueDataBar: 'Light blue Data Bar',
    PurpleDataBar: 'Purple Data Bar',
    GYRColorScale: 'Green - Yellow - Red Color Scale',
    RYGColorScale: 'Red - Yellow - Green Color Scale',
    GWRColorScale: 'Green - White - Red Color Scale',
    RWGColorScale: 'Red - White - Green Color Scale',
    BWRColorScale: 'Blue - White - Red Color Scale',
    RWBColorScale: 'Red - White - Blue Color Scale',
    WRColorScale: 'White - Red Color Scale',
    RWColorScale: 'Red - White Color Scale',
    GWColorScale: 'Green - White Color Scale',
    WGColorScale: 'White - Green Color Scale',
    GYColorScale: 'Green - Yellow Color Scale',
    YGColorScale: 'Yellow - Green Color Scale',
    ThreeArrowsColor: '3 Arrows (Colored)',
    ThreeArrowsGray: '3 Arrows (Gray)',
    ThreeTriangles: '3 Triangles',
    FourArrowsColor: '4 Arrows (Gray)',
    FourArrowsGray: '4 Arrows (Colored)',
    FiveArrowsColor: '5 Arrows (Gray)',
    FiveArrowsGray: '5 Arrows (Colored)',
    ThreeTrafficLights1: '3 Traffic Lights (Unrimmed)',
    ThreeTrafficLights2: '3 Traffic Lights (Rimmed)',
    ThreeSigns: '3 Signs',
    FourTrafficLights: '4 Traffic Lights',
    RedToBlack: 'Red To Black',
    ThreeSymbols1: '3 Symbols (Circled)',
    ThreeSymbols2: '3 Symbols (Uncircled)',
    ThreeFlags: '3 Flags',
    ThreeStars: '3 Stars',
    FourRatings: '4 Ratings',
    FiveQuarters: '5 Quarters',
    FiveRatings: '5 Ratings',
    FiveBoxes: '5 Boxes',
    Chart: 'Chart',
    Column: 'Column',
    Bar: 'Bar',
    Area: 'Area',
    Pie: 'Pie',
    Doughnut: 'Doughnut',
    PieAndDoughnut: 'Pie/Doughnut',
    Line: 'Line',
    LineMarker: 'Line with Markers',
    Radar: 'Radar',
    Scatter: 'Scatter',
    ChartDesign: 'Chart Design',
    ClusteredColumn: 'Clustered Column',
    StackedColumn: 'Stacked Column',
    StackedColumn100: '100% Stacked Column',
    ClusteredBar: 'Clustered Bar',
    StackedBar: 'Stacked Bar',
    StackedBar100: '100% Stacked Bar',
    StackedArea: 'Stacked Area',
    StackedArea100: '100% Stacked Area',
    StackedLine: 'Stacked Line',
    StackedLineMarker: 'Stacked Line with Markers',
    StackedLine100: '100% Stacked Line',
    StackedLine100Marker: '100% Stacked Line with Markers',
    AddChartElement: 'Add Chart Element',
    Axes: 'Axes',
    AxisTitle: 'Axis Title',
    ChartTitle: 'Chart Title',
    DataLabels: 'Data Labels',
    Gridlines: 'Gridlines',
    Legends: 'Legends',
    PrimaryHorizontal: 'Primary Horizontal',
    PrimaryVertical: 'Primary Vertical',
    None: 'None',
    AboveChart: 'Above Chart',
    Center: 'Center',
    InsideEnd: 'Inside End',
    InsideBase: 'Inside Base',
    OutsideEnd: 'OutSide End',
    PrimaryMajorHorizontal: 'Primary Major Horizontal',
    PrimaryMajorVertical: 'Primary Major Vertical',
    PrimaryMinorHorizontal: 'Primary Minor Horizontal',
    PrimaryMinorVertical: 'Primary Minor Vertical',
    Right: 'Right',
    Left: 'Left',
    Bottom: 'Bottom',
    Top: 'Top',
    SwitchRowColumn: 'Switch Row/Column',
    ChartTheme: 'Chart Theme',
    ChartType: 'Chart Type',
    Material: 'Material',
    Fabric: 'Fabric',
    Bootstrap: 'Bootstrap',
    HighContrastLight: 'HighContrast Light',
    MaterialDark: 'Material Dark',
    FabricDark: 'Fabric Dark',
    HighContrast: 'HighContrast',
    BootstrapDark: 'Bootstrap Dark',
    Bootstrap4: 'Bootstrap4',
    Bootstrap5Dark: 'Bootstrap5 Dark',
    Bootstrap5: 'Bootstrap5',
    Tailwind: 'Tailwind',
    TailwindDark: 'Tailwind Dark',
    VerticalAxisTitle: 'Vertical Axis Title',
    HorizontalAxisTitle: 'Horizontal Axis Title',
    EnterTitle: 'Enter Title',
    ProtectWorkbook: 'Protect Workbook',
    Password: 'Password (optional):',
    EnterThePassword: 'Enter the password',
    ConfirmPassword: 'Confirm Password',
    EnterTheConfirmPassword: 'Re-enter your password',
    PasswordAlert: 'Confirmation password is not identical',
    UnprotectWorkbook: 'Unprotect Workbook',
    UnprotectPasswordAlert: 'The password you supplied is not correct.',
    IncorrectPassword: 'Unable to open the file or worksheet with the given password',
    PasswordAlertMsg: 'Please enter the password',
    ConfirmPasswordAlertMsg: 'Please enter the confirm password',
    IsProtected: 'is protected',
    PDF: 'PDF Document',
    AutoFillMergeAlertMsg: 'To do this, all the merged cells need to be the same size.',
    FillSeries: 'Fill Series',
    CopyCells: 'Copy Cells',
    FillFormattingOnly: 'Fill Formatting Only',
    FillWithoutFormatting: 'Fill Without Formatting',
    CustomFormat: 'Custom Number Formats',
    CustomFormatPlaceholder: 'Type or Select a custom format',
    CustomFormatTypeList: 'Type',
    UnprotectWorksheet: 'Unprotect Sheet',
    ReEnterPassword: 'Re-enter password to proceed',
    SheetPassword: 'Password to unprotect sheet:',
    Fluent: 'Fluent',
    FluentDark: 'Fluent Dark',
    Fluent2: 'Fluent 2',
    Fluent2Dark: 'Fluent 2 Dark',
    Fluent2HighContrast: 'Fluent 2 HighContrast',
    Material3: 'Material 3',
    Material3Dark: 'Material 3 Dark',
    CellReferenceTypoError: 'We found a typo in your cell reference. Do you want to correct this reference as follows?',
    InvalidFormulaError: 'We found that you typed a formula which is invalid.',
    InvalidArguments: 'We found that you typed a formula with an invalid arguments.',
    EmptyExpression: 'We found that you typed a formula with an empty expression.',
    MismatchedParenthesis: 'We found that you typed a formula with one or more missing opening or closing parenthesis.',
    ImproperFormula: 'We found that you typed a formula which is improper.',
    WrongNumberOfArguments: 'We found that you typed a formula with a wrong number of arguments.',
    Requires3Arguments: 'We found that you typed a formula which requires 3 arguments.',
    MismatchedStringQuotes: 'We found that you typed a formula with a mismatched quotes.',
    FormulaCircularRef: 'We found that you typed a formula with a circular reference.',
    AddCurrentSelection: 'Add current selection to filter',
    ExternalWorkbook: 'An imported excel file contains an external workbook reference. Do you want to import that file?'
};

import { editAlert, readonlyAlert, completeAction, createNoteIndicator } from '../index';
import { isValidation, checkDateFormat, applyCellFormat, activeCellChanged } from '../../workbook/common/index';
import { getCell, setCell, getFormattedCellObject, getFormattedBarText, getRow, getRowHeight } from '../../workbook/index';
import { FormValidator, NumericTextBox } from '@syncfusion/ej2-inputs';
import { EventHandler, remove, closest, isNullOrUndefined, select, Browser, getNumericObject } from '@syncfusion/ej2-base';
import { dialog, locale, initiateDataValidation, invalidData, editOperation, keyUp, focus, removeElements } from '../common/index';
import { formulaBarOperation, removeDataValidation } from '../common/index';
import { CheckBox } from '@syncfusion/ej2-buttons';
import { isHiddenRow, setRow, beginAction, getSwapRange, checkColumnValidation } from '../../workbook/index';
import { getRangeIndexes, getIndexesFromAddress, getCellIndexes, cellValidation, isInMultipleRange } from '../../workbook/common/index';
import { getData, isCellReference, parseLocaleNumber } from '../../workbook/index';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { getSheet, getSheetIndex, checkIsFormula, isReadOnly } from '../../workbook/index';
import { getColumn, isLocked, validationHighlight, formulaInValidation } from '../../workbook/index';
import { updateCell, isNumber, addListValidationDropdown } from '../../workbook/common/index';
import { parseThousandSeparator } from '../../workbook/common/internalization';
import { getBorderHeight, rowHeightChanged } from '../../spreadsheet/index';
/**
 * Represents Data Validation support for Spreadsheet.
 */
var DataValidation = /** @class */ (function () {
    /**
     * Constructor for the Spreadsheet Data Validation module.
     *
     * @param {Spreadsheet} parent - Constructor for the Spreadsheet Data Validation module.
     */
    function DataValidation(parent) {
        this.divElements = [];
        this.spanElements = [];
        this.inputElements = [];
        this.checkBoxElements = [];
        this.numericTextBoxElements = [];
        this.dropDownListElements = [];
        this.parent = parent;
        this.addEventListener();
    }
    /**
     * To destroy the Data Validation module.
     *
     * @returns {void}
     */
    DataValidation.prototype.destroy = function () {
        this.removeEventListener();
        var validationArgs = {};
        if (!this.parent.isDestroyed && !this.parent.refreshing) {
            var activeCellIndex = getCellIndexes(this.parent.getActiveSheet().activeCell);
            var activeCell = this.parent.getCell(activeCellIndex[0], activeCellIndex[1]);
            if (activeCell) {
                validationArgs = { rowIdx: activeCellIndex[0], colIdx: activeCellIndex[1], td: activeCell, isRefresh: true };
            }
        }
        this.removeListDropdownHandler(this.parent.element.querySelector('.e-validation-list'), validationArgs);
        var dataValPopup = select('#' + this.parent.element.id + '_datavalidation-popup');
        if (dataValPopup) {
            dataValPopup.remove();
        }
        this.typeData = [];
        this.operatorData = [];
        if (this.dataList) {
            this.dataList = null;
        }
        if (this.formObj) {
            this.formObj.destroy();
            this.formObj = null;
        }
        this.parent = null;
    };
    DataValidation.prototype.addEventListener = function () {
        if (Browser.isDevice && Browser.info.name === 'safari' && (Browser.isIos || Browser.isIos7)) {
            EventHandler.add(this.parent.element, 'touchend', this.listOpen, this);
        }
        else {
            EventHandler.add(this.parent.element, 'dblclick', this.listOpen, this);
        }
        this.parent.on(initiateDataValidation, this.initiateDataValidationHandler, this);
        this.parent.on(invalidData, this.invalidDataHandler, this);
        this.parent.on(isValidation, this.checkDataValidation, this);
        this.parent.on(activeCellChanged, this.listHandler, this);
        this.parent.on(keyUp, this.keyUpHandler, this);
        this.parent.on(removeDataValidation, this.removeValidationHandler, this);
        this.parent.on(validationHighlight, this.invalidElementHandler, this);
        this.parent.on(rowHeightChanged, this.listValidationHeightHandler, this);
        this.parent.on(addListValidationDropdown, this.addListValidationDropdownHandler, this);
    };
    DataValidation.prototype.removeEventListener = function () {
        if (Browser.isDevice && Browser.info.name === 'safari' && (Browser.isIos || Browser.isIos7)) {
            EventHandler.remove(this.parent.element, 'touchend', this.listOpen);
        }
        else {
            EventHandler.remove(this.parent.element, 'dblclick', this.listOpen);
        }
        if (!this.parent.isDestroyed) {
            this.parent.off(initiateDataValidation, this.initiateDataValidationHandler);
            this.parent.off(invalidData, this.invalidDataHandler);
            this.parent.off(isValidation, this.checkDataValidation);
            this.parent.off(activeCellChanged, this.listHandler);
            this.parent.off(keyUp, this.keyUpHandler);
            this.parent.off(removeDataValidation, this.removeValidationHandler);
            this.parent.off(validationHighlight, this.invalidElementHandler);
            this.parent.off(rowHeightChanged, this.listValidationHeightHandler);
            this.parent.off(addListValidationDropdown, this.addListValidationDropdownHandler);
        }
    };
    DataValidation.prototype.removeValidationHandler = function (eventArgs) {
        var sheet;
        var range;
        var args = { cancel: false, isColSelected: eventArgs.isCol };
        if (eventArgs.range && eventArgs.range.includes('!')) {
            range = eventArgs.range;
            sheet = getSheet(this.parent, getSheetIndex(this.parent, range.split('!')[0]));
            if (!sheet) {
                return;
            }
        }
        else {
            sheet = this.parent.getActiveSheet();
            range = sheet.name + '!';
            if (eventArgs.range) {
                range += eventArgs.range;
            }
            else {
                var rangeArgs = this.getRange(sheet.selectedRange);
                range += rangeArgs.range;
                args.isColSelected = rangeArgs.isColSelected;
            }
        }
        args.range = range;
        if (eventArgs.isAction) {
            this.parent.notify(beginAction, { eventArgs: args, action: 'removeValidation' });
        }
        if (!args.cancel) {
            this.parent.notify(cellValidation, { range: range, isRemoveValidation: true, viewport: this.parent.viewport });
            if (eventArgs.isAction) {
                delete args.cancel;
                this.parent.notify(completeAction, { eventArgs: args, action: 'removeValidation' });
            }
        }
    };
    DataValidation.prototype.updateNoteIndicator = function (td, rowIndex, columnIndex) {
        var noteIndicator = td.querySelector('.e-addNoteIndicator');
        if (noteIndicator) {
            remove(noteIndicator);
            this.parent.notify(createNoteIndicator, { targetElement: td, rowIndex: rowIndex, columnIndex: columnIndex, skipEvent: true });
        }
    };
    DataValidation.prototype.keyUpHandler = function (e) {
        var target = e.target;
        var dlgEle = this.parent.element.querySelector('.e-datavalidation-dlg');
        if (closest(target, '.e-values') && dlgEle && e.keyCode !== 13) {
            var valuesCont = dlgEle.querySelector('.e-values');
            var errorEle = valuesCont.querySelector('.e-dlg-error');
            var footerCont = dlgEle.querySelector('.e-footer-content');
            var primaryBut = footerCont.querySelector('.e-primary');
            if (primaryBut.hasAttribute('disabled')) {
                primaryBut.removeAttribute('disabled');
            }
            if (errorEle) {
                valuesCont.removeChild(errorEle);
            }
        }
    };
    DataValidation.prototype.listOpen = function (e) {
        var target = e.target;
        if (this.listObj && target.classList.contains('e-cell') && target.querySelector('.e-validation-list') && this.parent.isEdit) {
            this.listObj.showPopup();
        }
    };
    DataValidation.prototype.invalidDataHandler = function (args) {
        var eventArgs = { range: args.range || this.parent.dataValidationRange, cancel: false };
        if (!eventArgs.range) {
            return;
        }
        var actionArgs;
        if (!args.isPublic) {
            actionArgs = { eventArgs: eventArgs, action: args.isRemoveHighlight ? 'removeHighlight' : 'addHighlight' };
            this.parent.notify(beginAction, actionArgs);
            if (eventArgs.cancel) {
                return;
            }
        }
        if (args.isRemoveHighlight) {
            this.parent.removeInvalidHighlight(eventArgs.range);
        }
        else {
            this.parent.addInvalidHighlight(eventArgs.range);
        }
        if (!args.isPublic) {
            this.parent.notify(completeAction, actionArgs);
        }
    };
    DataValidation.prototype.listHandler = function () {
        var sheet = this.parent.getActiveSheet();
        var indexes = getCellIndexes(sheet.activeCell);
        var cell = getCell(indexes[0], indexes[1], sheet);
        var tdEle = this.parent.getCell(indexes[0], indexes[1]);
        if (!tdEle) {
            return;
        }
        this.removeListDropdownHandler(document.getElementsByClassName('e-validation-list')[0]);
        var validation = (cell && cell.validation) || (sheet.columns && sheet.columns[indexes[1]] &&
            sheet.columns[indexes[1]].validation);
        if (validation && validation.type === 'List') {
            if (validation.address && !isInMultipleRange(validation.address, indexes[0], indexes[1])) {
                return;
            }
            this.addListValidationDropdownHandler({ cell: cell, validation: validation, td: tdEle, rowIdx: indexes[0], colIdx: indexes[1] });
        }
        if (cell && cell.validation) {
            cell.validation = validation;
        }
    };
    DataValidation.prototype.removeListDropdownHandler = function (listEle, validationArgs) {
        if (listEle) {
            if (this.listObj) {
                this.listObj.destroy();
            }
            remove(listEle);
            if (!validationArgs) {
                if (!isNullOrUndefined(this.parent.selectionModule.previousActiveCell)) {
                    var pervActiveCellIdx = getCellIndexes(this.parent.selectionModule.previousActiveCell);
                    var pervActiveCellEle = this.parent.getCell(pervActiveCellIdx[0], pervActiveCellIdx[1]);
                    if (pervActiveCellEle) {
                        this.updateNoteIndicator(pervActiveCellEle, pervActiveCellIdx[0], pervActiveCellIdx[1]);
                    }
                }
            }
            else if (validationArgs.isRefresh) {
                this.updateNoteIndicator(validationArgs.td, validationArgs.rowIdx, validationArgs.colIdx);
            }
        }
    };
    DataValidation.prototype.addListValidationDropdownHandler = function (args) {
        var _this = this;
        var inCellDropDown = args.validation.inCellDropDown;
        if (args.isRefresh) {
            if (!args.td) {
                args.td = this.parent.getCell(args.rowIdx, args.colIdx);
                if (!args.td) {
                    return;
                }
            }
            this.removeListDropdownHandler(args.td.querySelector('.e-validation-list'), args);
            if (args.validation.type !== 'List') {
                return;
            }
            if (isNullOrUndefined(inCellDropDown)) {
                inCellDropDown = true;
            }
        }
        else {
            if (isNullOrUndefined(args.validation.ignoreBlank)) {
                args.validation.ignoreBlank = true;
            }
            if (isNullOrUndefined(inCellDropDown)) {
                inCellDropDown = args.validation.inCellDropDown = true;
            }
        }
        if (inCellDropDown) {
            var ddlCont = this.parent.createElement('div', { className: 'e-validation-list' });
            var ddlEle = this.parent.createElement('input', { id: this.parent.element.id + 'listValid' });
            ddlCont.appendChild(ddlEle);
            var isDevice_1;
            var tdEle_1 = args.td;
            var parent_1 = tdEle_1.getElementsByClassName('e-wrap-content')[0] || tdEle_1;
            this.listValidationHeightHandler({ ddlCont: ddlCont });
            parent_1.insertBefore(ddlCont, parent_1.firstChild);
            var dataSource = this.getListDataSource(args.validation);
            this.listObj = new DropDownList({
                index: this.setDropDownListIndex(dataSource, args.cell),
                dataSource: dataSource,
                fields: { text: 'text', value: 'id' },
                width: '0px',
                popupHeight: '200px',
                change: function () { return _this.listValueChange(_this.listObj.text); },
                beforeOpen: function () {
                    isDevice_1 = window.browserDetails.isDevice;
                    if (isDevice_1) {
                        window.browserDetails.isDevice = false;
                    }
                },
                open: function (args) {
                    args.popup.offsetX = -(tdEle_1.offsetWidth - 20) + 4;
                    args.popup.offsetY = -(tdEle_1.querySelector('.e-control-wrapper.e-ddl').offsetHeight - 18);
                    args.popup.element.style.width = tdEle_1.offsetWidth - 1 + 'px';
                    if (isDevice_1) {
                        window.browserDetails.isDevice = true;
                    }
                },
                close: function (args) {
                    if (args.event && (args.event.keyCode === 13 ||
                        (args.event.altKey && args.event.keyCode === 38))) {
                        args.event.preventDefault();
                        args.event.stopPropagation();
                    }
                    focus(_this.parent.element);
                }
            });
            this.listObj.appendTo(ddlEle);
            this.updateNoteIndicator(tdEle_1, args.rowIdx, args.colIdx);
        }
    };
    DataValidation.prototype.listValidationHeightHandler = function (args) {
        var sheet = this.parent.getActiveSheet();
        var indexes = getCellIndexes(sheet.activeCell);
        var cell = getCell(indexes[0], indexes[1], sheet);
        var validation = (cell && cell.validation) || (sheet.columns && sheet.columns[indexes[1]] &&
            sheet.columns[indexes[1]].validation);
        if (validation && validation.type === 'List') {
            if (validation.inCellDropDown) {
                var tdRowHeight = getRowHeight(sheet, indexes[0], true) - getBorderHeight(indexes[0], indexes[1], sheet);
                if (tdRowHeight <= 18) {
                    var ddlCont = args.ddlCont || this.parent.element.querySelector('.e-validation-list');
                    if (ddlCont) {
                        ddlCont.style.height = tdRowHeight + 'px';
                    }
                }
                else if (!args.ddlCont) {
                    var ddlCont = this.parent.element.querySelector('.e-validation-list');
                    if (ddlCont) {
                        ddlCont.style.removeProperty('height');
                    }
                }
            }
        }
    };
    DataValidation.prototype.setDropDownListIndex = function (dataSource, cell) {
        if (cell && !isNullOrUndefined(cell.value)) {
            var cellVal = cell.value.toString();
            var isNumVal = isNumber(cellVal);
            var numObj = isNumVal && getNumericObject(this.parent.locale);
            for (var idx = 0, len = dataSource.length; idx < len; idx++) {
                if (dataSource[idx].text === cellVal || (isNumVal &&
                    this.parseValidationValue(dataSource[idx].text, numObj).toString() === cellVal)) {
                    return idx;
                }
            }
        }
        return null;
    };
    DataValidation.prototype.getListDataSource = function (validation) {
        var _this = this;
        var data = [];
        var count = 0;
        var definedNames = this.parent.definedNames;
        var value = validation.value1;
        var isRange = value.indexOf('=') !== -1;
        if (definedNames.length > 0 && isRange) {
            var listValue = value.split('=')[1];
            for (var idx = 0, len = definedNames.length; idx < len; idx++) {
                if (definedNames[idx].name === listValue) {
                    var definedNameRange = definedNames[idx].refersTo;
                    while (definedNameRange.includes('\'')) {
                        definedNameRange = definedNameRange.replace('\'', '');
                    }
                    value = definedNameRange;
                }
            }
        }
        if (isRange) {
            var sheet = void 0;
            var address = void 0;
            if (value.indexOf('!') > -1) {
                var rangeArr = value.split('=')[1].split('!');
                if (rangeArr[0].startsWith('\'') && rangeArr[0].endsWith('\'')) {
                    rangeArr[0] = rangeArr[0].substring(1, rangeArr[0].length - 1);
                }
                sheet = getSheet(this.parent, getSheetIndex(this.parent, rangeArr[0]));
                address = rangeArr[1];
            }
            else {
                sheet = this.parent.getActiveSheet();
                address = value.substring(1);
            }
            var activeSheet_1 = this.parent.getActiveSheet();
            if (sheet.name !== activeSheet_1.name) {
                var isNotLoaded_1;
                var selectedRange_1 = getRangeIndexes(activeSheet_1.selectedRange);
                sheet.ranges.forEach(function (range) {
                    if (!range.info || !range.info.loadedRange || !range.info.loadedRange.length) {
                        isNotLoaded_1 = true;
                        return;
                    }
                });
                if (isNotLoaded_1) {
                    this.parent.showSpinner();
                    getData(this.parent, sheet.name + "!" + address).then(function () {
                        _this.parent.hideSpinner();
                        if (activeSheet_1.name === _this.parent.getActiveSheet().name) {
                            var curRange = getRangeIndexes(_this.parent.getActiveSheet().selectedRange);
                            if (curRange[0] === selectedRange_1[0] && curRange[1] === selectedRange_1[1]) {
                                var dataSource = _this.getListDataSource(validation);
                                _this.listObj.dataSource = dataSource;
                                _this.listObj.index = _this.setDropDownListIndex(dataSource, getCell(curRange[0], curRange[1], activeSheet_1));
                                _this.listObj.dataBind();
                            }
                        }
                    });
                }
            }
            var indexes = void 0;
            var range = address.split(':');
            if ((range[0].match(/[a-z]+$/ig) && range[1].match(/[a-z]+$/ig)) || (range[0].match(/^[0-9]/g) && range[1].match(/^[0-9]/g))) {
                var addressInfo = this.parent.getIndexes(address);
                if (addressInfo.isCol) {
                    indexes = [0, addressInfo.startIdx, sheet.usedRange.rowIndex, addressInfo.startIdx];
                }
                else {
                    indexes = [addressInfo.startIdx, 0, addressInfo.startIdx, sheet.usedRange.colIndex];
                }
            }
            else {
                indexes = getRangeIndexes(address);
            }
            var cell = void 0;
            for (var rowIdx = indexes[0]; rowIdx <= indexes[2]; rowIdx++) {
                if (!sheet.rows[rowIdx]) {
                    setRow(sheet, rowIdx, {});
                }
                for (var colIdx = indexes[1]; colIdx <= indexes[3]; colIdx++) {
                    if (!sheet.rows[rowIdx].cells) {
                        setCell(rowIdx, colIdx, sheet, {});
                    }
                    count += 1;
                    cell = sheet.rows[rowIdx].cells[colIdx];
                    var formattedText = this.parent.getDisplayText(cell) || '';
                    data.push({ text: formattedText, id: 'list-' + count });
                }
            }
        }
        else {
            var listValues = this.getListOfValues(value);
            for (var idx = 0; idx < listValues.length; idx++) {
                count += 1;
                data.push({ text: listValues[idx], id: 'list-' + count });
            }
        }
        return data;
    };
    DataValidation.prototype.listValueChange = function (value) {
        var sheet = this.parent.getActiveSheet();
        var cellIdx = getIndexesFromAddress(sheet.activeCell);
        var cellObj = Object.assign({}, getCell(cellIdx[0], cellIdx[1], sheet));
        if (sheet.isProtected && isLocked(cellObj, getColumn(sheet, cellIdx[1]))) {
            this.parent.notify(editAlert, null);
        }
        else if (isReadOnly(cellObj, getColumn(sheet, cellIdx[1]), getRow(sheet, cellIdx[0]))) {
            this.parent.notify(readonlyAlert, null);
        }
        else {
            if (this.parent.isEdit) {
                this.parent.closeEdit();
            }
            var args = { value: value, oldValue: cellObj.value, address: sheet.name + '!' + sheet.activeCell, cancel: false };
            this.parent.notify(beginAction, { action: 'cellSave', eventArgs: args });
            if (args.cancel) {
                return;
            }
            var cancelled = updateCell(this.parent, sheet, { cell: { value: value, formula: '' }, rowIdx: cellIdx[0], colIdx: cellIdx[1], valChange: true, lastCell: true,
                uiRefresh: true, checkCF: true });
            if (!cancelled) {
                var cell = getCell(cellIdx[0], cellIdx[1], sheet, false, true);
                delete cell.formula;
                this.parent.notify(formulaBarOperation, { action: 'refreshFormulabar', cell: cell });
                this.parent.notify(completeAction, { action: 'cellSave', eventArgs: { value: value, oldValue: cellObj.value, address: sheet.name + '!' + sheet.activeCell } });
            }
        }
    };
    DataValidation.prototype.getRange = function (range) {
        var indexes = getRangeIndexes(range);
        var sheet = this.parent.getActiveSheet();
        var maxRowCount = sheet.rowCount;
        var maxColCount = sheet.colCount;
        var isColSelected;
        if (indexes[2] === maxRowCount - 1 && indexes[0] === 0) {
            range = range.replace(/[0-9]/g, '');
            isColSelected = true;
        }
        else if (indexes[3] === maxColCount - 1 && indexes[2] === 0) {
            range = range.replace(/\D/g, '');
        }
        return { range: range, isColSelected: isColSelected };
    };
    DataValidation.prototype.initiateDataValidationHandler = function (okClick, noClick) {
        var _this = this;
        var l10n = this.parent.serviceLocator.getService(locale);
        var type;
        var operator;
        var value1;
        var value2;
        var ignoreBlank = true;
        var inCellDropDown = true;
        var isNew = true;
        var sheet = this.parent.getActiveSheet();
        var cell;
        var range = sheet.selectedRange;
        var indexes = getSwapRange(getRangeIndexes(range));
        range = this.getRange(range).range;
        var validationArgs = this.validateRange(indexes, sheet);
        if (!validationArgs.extendValidation && !validationArgs.moreValidation || okClick) {
            for (var rowIdx = indexes[0]; rowIdx <= indexes[2]; rowIdx++) {
                if (sheet.rows[rowIdx]) {
                    for (var colIdx = indexes[1]; colIdx <= indexes[3]; colIdx++) {
                        if (sheet.rows[rowIdx].cells && sheet.rows[rowIdx].cells[colIdx]) {
                            cell = sheet.rows[rowIdx].cells[colIdx];
                            if (cell.validation) {
                                isNew = false;
                                type = cell.validation.type;
                                operator = cell.validation.operator;
                                value1 = cell.validation.value1;
                                value2 = cell.validation.value2;
                                ignoreBlank = !isNullOrUndefined(cell.validation.ignoreBlank) ?
                                    cell.validation.ignoreBlank : ignoreBlank;
                                inCellDropDown = !isNullOrUndefined(cell.validation.inCellDropDown) ?
                                    cell.validation.inCellDropDown : inCellDropDown;
                            }
                        }
                    }
                }
            }
            if (isNew) {
                for (var i = indexes[1]; i <= indexes[3]; i++) {
                    var column = getColumn(sheet, i);
                    if (column && column.validation) {
                        isNew = false;
                        type = column.validation.type;
                        operator = column.validation.operator;
                        value1 = column.validation.value1;
                        value2 = column.validation.value2;
                        ignoreBlank = !isNullOrUndefined(column.validation.ignoreBlank) ?
                            column.validation.ignoreBlank : ignoreBlank;
                        inCellDropDown = !isNullOrUndefined(column.validation.inCellDropDown) ?
                            column.validation.inCellDropDown : inCellDropDown;
                    }
                }
            }
            if (!this.parent.element.querySelector('.e-datavalidation-dlg')) {
                var dialogInst_1 = this.parent.serviceLocator.getService(dialog);
                dialogInst_1.show({
                    width: 375, showCloseIcon: true, isModal: true, cssClass: 'e-datavalidation-dlg',
                    header: l10n.getConstant('DataValidation'),
                    beforeOpen: function (args) {
                        var dlgArgs = {
                            dialogName: 'ValidationDialog', element: args.element,
                            target: args.target, cancel: args.cancel
                        };
                        _this.parent.trigger('dialogBeforeOpen', dlgArgs);
                        if (dlgArgs.cancel) {
                            args.cancel = true;
                        }
                        if (noClick) {
                            isNew = true;
                        }
                        dialogInst_1.dialogInstance.content =
                            _this.dataValidationContent(isNew, type, operator, value1, value2, ignoreBlank, inCellDropDown, range);
                        dialogInst_1.dialogInstance.dataBind();
                        focus(_this.parent.element);
                    },
                    beforeClose: this.dialogBeforeClose.bind(this),
                    buttons: [{
                            buttonModel: {
                                content: l10n.getConstant('ClearAll'),
                                cssClass: 'e-btn e-clearall-btn e-flat'
                            },
                            click: function () {
                                dialogInst_1.dialogInstance.content =
                                    _this.dataValidationContent(true, type, operator, value1, value2, ignoreBlank, inCellDropDown, range);
                                dialogInst_1.dialogInstance.dataBind();
                            }
                        },
                        {
                            buttonModel: {
                                content: l10n.getConstant('Apply'), isPrimary: true
                            },
                            click: function () {
                                _this.dlgClickHandler(dialogInst_1);
                            }
                        }]
                });
                dialogInst_1.dialogInstance.refresh();
            }
        }
        else {
            if (validationArgs.moreValidation) {
                this.moreValidationDlg();
            }
            if (validationArgs.extendValidation) {
                this.extendValidationDlg();
            }
        }
    };
    DataValidation.prototype.dataValidationContent = function (isNew, type, operator, val1, val2, ignoreBlank, inCellDropDown, range) {
        var _this = this;
        var l10n = this.parent.serviceLocator.getService(locale);
        var value1;
        var value2;
        if (isNew) {
            value1 = value2 = '0';
        }
        else {
            if (type === 'Date' || type === 'Time') {
                var getFormattedDate = function (val) {
                    if (isNumber(val)) {
                        var args = { cell: { value: val }, type: type.toLowerCase(),
                            value: val };
                        _this.parent.notify(getFormattedBarText, args);
                        return args.value;
                    }
                    return val;
                };
                value1 = getFormattedDate(val1);
                value2 = getFormattedDate(val2);
            }
            else {
                var getFormattedValue = function (val) {
                    if (isNumber(val)) {
                        val = val.toString();
                        var localeObj = getNumericObject(_this.parent.locale);
                        if (localeObj.decimal !== '.' && val.includes('.')) {
                            val = val.replace('.', localeObj.decimal);
                        }
                    }
                    return val;
                };
                value1 = getFormattedValue(val1);
                value2 = getFormattedValue(val2);
            }
        }
        var dlgContent = this.parent.createElement('div', { className: 'e-validation-dlg' });
        var cellRangeCont = this.parent.createElement('div', { className: 'e-cellrange' });
        var allowDataCont = this.parent.createElement('div', { className: 'e-allowdata' });
        var valuesCont = this.parent.createElement('div', { className: 'e-values' });
        var ignoreBlankCont = this.parent.createElement('div', { className: 'e-ignoreblank' });
        this.divElements.push(dlgContent);
        this.divElements.push(cellRangeCont);
        this.divElements.push(allowDataCont);
        this.divElements.push(valuesCont);
        this.divElements.push(ignoreBlankCont);
        dlgContent.appendChild(cellRangeCont);
        dlgContent.appendChild(allowDataCont);
        dlgContent.appendChild(valuesCont);
        dlgContent.appendChild(ignoreBlankCont);
        var cellRangeText = this.parent.createElement('span', { className: 'e-header' });
        cellRangeText.innerText = l10n.getConstant('CellRange');
        var cellRangeEle = this.parent.createElement('input', {
            className: 'e-input',
            attrs: { value: range, 'aria-label': l10n.getConstant('CellRange') }
        });
        cellRangeCont.appendChild(cellRangeText);
        cellRangeCont.appendChild(cellRangeEle);
        var allowCont = this.parent.createElement('div', { className: 'e-allow' });
        var dataCont = this.parent.createElement('div', { className: 'e-data' });
        allowDataCont.appendChild(allowCont);
        allowDataCont.appendChild(dataCont);
        var allowText = this.parent.createElement('span', { className: 'e-header' });
        allowText.innerText = l10n.getConstant('Allow');
        this.typeData = [
            { text: l10n.getConstant('WholeNumber'), id: 'type-1' },
            { text: l10n.getConstant('Decimal'), id: 'type-2' },
            { text: l10n.getConstant('Date'), id: 'type-3' },
            { text: l10n.getConstant('Time'), id: 'type-4' },
            { text: l10n.getConstant('TextLength'), id: 'type-5' },
            { text: l10n.getConstant('List'), id: 'type-6' }
        ];
        this.operatorData = [
            { text: l10n.getConstant('Between'), id: 'operator-1' },
            { text: l10n.getConstant('NotBetween'), id: 'operator-2' },
            { text: l10n.getConstant('EqualTo'), id: 'operator-3' },
            { text: l10n.getConstant('NotEqualTo'), id: 'operator-4' },
            { text: l10n.getConstant('GreaterThan'), id: 'operator-5' },
            { text: l10n.getConstant('LessThan'), id: 'operator-6' },
            { text: l10n.getConstant('GreaterThanOrEqualTo'), id: 'operator-7' },
            { text: l10n.getConstant('LessThanOrEqualTo'), id: 'operator-8' }
        ];
        var allowSelectEle = this.parent.createElement('input', { className: 'e-select' });
        this.spanElements.push(cellRangeText);
        this.inputElements.push(cellRangeEle);
        this.divElements.push(allowCont);
        this.divElements.push(dataCont);
        this.spanElements.push(allowText);
        this.inputElements.push(allowSelectEle);
        if (type) {
            type = this.formattedType(type);
        }
        var allowIdx = 0;
        if (!isNew) {
            for (var idx = 0; idx < this.typeData.length; idx++) {
                if (type === this.formattedType(this.typeData[idx].text)) {
                    allowIdx = idx;
                    break;
                }
            }
        }
        if (isNew || type !== 'List') {
            var dataIdx = 0;
            var dataText = this.parent.createElement('span', { className: 'e-header' });
            dataText.innerText = l10n.getConstant('Data');
            var dataSelectEle = this.parent.createElement('input', { className: 'e-select' });
            if (!isNew) {
                for (var idx = 0; idx < this.operatorData.length; idx++) {
                    if (operator === this.formattedValue(this.operatorData[idx].text)) {
                        dataIdx = idx;
                        break;
                    }
                }
            }
            dataCont.appendChild(dataText);
            dataCont.appendChild(dataSelectEle);
            this.spanElements.push(dataText);
            this.inputElements.push(dataSelectEle);
            this.dataList = new DropDownList({
                dataSource: this.operatorData,
                index: dataIdx,
                popupHeight: '200px',
                change: this.userInput.bind(this)
            });
            this.dropDownListElements.push(this.dataList);
            this.dataList.appendTo(dataSelectEle);
        }
        else {
            var ignoreBlankEle_1 = this.parent.createElement('input', { className: 'e-checkbox' });
            dataCont.appendChild(ignoreBlankEle_1);
            var ignoreBlankObj_1 = new CheckBox({ label: l10n.getConstant('InCellDropDown'), checked: inCellDropDown });
            this.checkBoxElements.push(ignoreBlankObj_1);
            ignoreBlankObj_1.appendTo(ignoreBlankEle_1);
            this.inputElements.push(ignoreBlankEle_1);
        }
        allowCont.appendChild(allowText);
        allowCont.appendChild(allowSelectEle);
        this.typeList = new DropDownList({
            dataSource: this.typeData,
            index: allowIdx,
            popupHeight: '200px',
            change: this.userInput.bind(this)
        });
        this.dropDownListElements.push(this.typeList);
        this.typeList.appendTo(allowSelectEle);
        if (isNew || (this.typeList.value !== l10n.getConstant('List') && (this.dataList.value === l10n.getConstant('Between') || this.dataList.value === l10n.getConstant('NotBetween')))) {
            var minimumCont = this.parent.createElement('div', { className: 'e-minimum' });
            var maximumCont = this.parent.createElement('div', { className: 'e-maximum' });
            valuesCont.appendChild(minimumCont);
            valuesCont.appendChild(maximumCont);
            var minimumText = this.parent.createElement('span', { className: 'e-header' });
            minimumText.innerText = l10n.getConstant('Minimum');
            var maximumText = this.parent.createElement('span', { className: 'e-header' });
            maximumText.innerText = l10n.getConstant('Maximum');
            var minimumInp = this.parent.createElement('input', {
                id: 'minvalue',
                className: 'e-input', attrs: { value: value1, 'aria-label': l10n.getConstant('Minimum') }
            });
            var maximumInp = this.parent.createElement('input', {
                id: 'maxvalue',
                className: 'e-input', attrs: { value: value2, 'aria-label': l10n.getConstant('Maximum') }
            });
            minimumCont.appendChild(minimumText);
            minimumCont.appendChild(minimumInp);
            maximumCont.appendChild(maximumText);
            maximumCont.appendChild(maximumInp);
            this.divElements.push(minimumCont);
            this.divElements.push(maximumCont);
            this.spanElements.push(minimumText);
            this.spanElements.push(maximumText);
            this.inputElements.push(minimumInp);
            this.inputElements.push(maximumInp);
            var numericMin = new NumericTextBox({
                value: 0
            });
            this.numericTextBoxElements.push(numericMin);
            numericMin.appendTo('#minvalue');
            var numericMax = new NumericTextBox({
                value: 0
            });
            this.numericTextBoxElements.push(numericMax);
            numericMax.appendTo('#maxvalue');
        }
        else if (!isNew && type === 'List') {
            var valueText = this.parent.createElement('span', { className: 'e-header' });
            valueText.innerText = l10n.getConstant('Sources');
            var valueEle = this.parent.createElement('input', { className: 'e-input', attrs: { value: value1 } });
            valuesCont.appendChild(valueText);
            valuesCont.appendChild(valueEle);
            this.spanElements.push(valueText);
            this.inputElements.push(valueEle);
        }
        else {
            var valueText = this.parent.createElement('span', { className: 'e-header' });
            valueText.innerText = l10n.getConstant('Value');
            var valueEle = this.parent.createElement('input', { className: 'e-input', attrs: { value: value1 } });
            valuesCont.appendChild(valueText);
            valuesCont.appendChild(valueEle);
            this.spanElements.push(valueText);
            this.inputElements.push(valueEle);
        }
        var isChecked = ignoreBlank;
        var ignoreBlankEle = this.parent.createElement('input', { className: 'e-checkbox' });
        ignoreBlankCont.appendChild(ignoreBlankEle);
        var ignoreBlankObj = new CheckBox({ label: l10n.getConstant('IgnoreBlank'), checked: isChecked });
        this.checkBoxElements.push(ignoreBlankObj);
        ignoreBlankObj.appendTo(ignoreBlankEle);
        this.inputElements.push(ignoreBlankEle);
        return dlgContent;
    };
    DataValidation.prototype.dialogBeforeClose = function () {
        this.checkBoxElements.forEach(function (checkbox) {
            if (checkbox && checkbox.element) {
                checkbox.destroy();
                checkbox.element.remove();
            }
        });
        this.checkBoxElements = [];
        this.numericTextBoxElements.forEach(function (numericTextBox) {
            if (numericTextBox && numericTextBox.element) {
                numericTextBox.destroy();
                numericTextBox.element.remove();
            }
        });
        this.numericTextBoxElements = [];
        this.dropDownListElements.forEach(function (dropDownList) {
            if (dropDownList && dropDownList.element) {
                dropDownList.destroy();
                dropDownList.element.remove();
            }
        });
        this.dropDownListElements = [];
        removeElements(this.spanElements);
        this.spanElements = [];
        removeElements(this.inputElements);
        this.inputElements = [];
        removeElements(this.divElements);
        this.divElements = [];
    };
    DataValidation.prototype.validateRange = function (indexes, sheet) {
        var moreValidation = false;
        var extendValidation = false;
        var type = [];
        var operator = [];
        var value1 = [];
        var value2 = [];
        for (var rowIndex = indexes[0]; rowIndex <= indexes[2]; rowIndex++) {
            if (sheet.rows[rowIndex]) {
                for (var colIdx = indexes[1]; colIdx <= indexes[3]; colIdx++) {
                    if (sheet.rows[rowIndex].cells && sheet.rows[rowIndex].cells[colIdx]) {
                        var cell = sheet.rows[rowIndex].cells[colIdx];
                        if (cell.validation) {
                            type.push(cell.validation.type);
                            operator.push(cell.validation.operator);
                            value1.push(cell.validation.value1);
                            value2.push(cell.validation.value2);
                        }
                    }
                }
            }
        }
        for (var i = indexes[1]; i <= indexes[3]; i++) {
            var column = getColumn(sheet, i);
            if (column && column.validation) {
                type.push(column.validation.type);
                operator.push(column.validation.operator);
                value1.push(column.validation.value1);
                value2.push(column.validation.value2);
            }
        }
        var tmp = [];
        for (var i = 0; i < type.length; i++) {
            if (tmp.indexOf(type[i]) === -1) {
                tmp.push(type[i]);
            }
        }
        if (tmp.length > 1) {
            moreValidation = true;
        }
        if (!moreValidation) {
            tmp = [];
            for (var j = 0; j < operator.length; j++) {
                if (tmp.indexOf(operator[j]) === -1) {
                    tmp.push(operator[j]);
                }
            }
            if (tmp.length > 1) {
                moreValidation = true;
            }
        }
        if (!moreValidation) {
            tmp = [];
            for (var j = 0; j < value1.length; j++) {
                if (tmp.indexOf(value1[j]) === -1) {
                    tmp.push(value1[j]);
                }
            }
            if (tmp.length > 1) {
                moreValidation = true;
            }
        }
        if (!moreValidation) {
            tmp = [];
            for (var j = 0; j < value2.length; j++) {
                if (tmp.indexOf(value2[j]) === -1) {
                    tmp.push(value2[j]);
                }
            }
            if (tmp.length > 1) {
                moreValidation = true;
            }
        }
        if (!moreValidation) {
            var count = 0;
            var cellCount = 0;
            for (var startRow = indexes[0]; startRow <= indexes[2]; startRow++) {
                if (sheet.rows[startRow]) {
                    for (var colIdx = indexes[1]; colIdx <= indexes[3]; colIdx++) {
                        if (sheet.rows[startRow].cells && sheet.rows[startRow].cells[colIdx]) {
                            var cell = sheet.rows[startRow].cells[colIdx];
                            cellCount++;
                            if (cell.validation) {
                                count++;
                            }
                        }
                    }
                }
            }
            if (count === 0) {
                for (var i = indexes[1]; i <= indexes[3]; i++) {
                    var column = getColumn(sheet, i);
                    if (column && column.validation) {
                        count++;
                    }
                }
            }
            if (count > 0 && cellCount > 1 && count !== cellCount) {
                extendValidation = true;
            }
        }
        return { moreValidation: moreValidation, extendValidation: extendValidation };
    };
    DataValidation.prototype.moreValidationDlg = function () {
        var _this = this;
        var l10n = this.parent.serviceLocator.getService(locale);
        var dialogInst = this.parent.serviceLocator.getService(dialog);
        var skip = false;
        var dlg = {
            width: 350, isModal: true, showCloseIcon: true, cssClass: 'e-goto-dlg',
            header: l10n.getConstant('Spreadsheet'),
            beforeOpen: function (args) {
                var dlgArgs = {
                    dialogName: 'MoreValidation',
                    element: args.element, target: args.target, cancel: args.cancel
                };
                _this.parent.trigger('dialogBeforeOpen', dlgArgs);
                if (dlgArgs.cancel) {
                    args.cancel = true;
                }
                dialogInst.dialogInstance.content = l10n.getConstant('MoreValidation');
                dialogInst.dialogInstance.dataBind();
                focus(_this.parent.element);
            },
            buttons: [{
                    buttonModel: {
                        content: l10n.getConstant('Ok'), isPrimary: true, cssClass: 'e-btn-goto-ok'
                    },
                    click: function () {
                        dialogInst.hide();
                        skip = true;
                    }
                }], close: function () {
                if (skip) {
                    _this.initiateDataValidationHandler(true);
                    skip = false;
                }
                else {
                    dialogInst.hide();
                }
            }
        };
        dialogInst.show(dlg);
    };
    DataValidation.prototype.extendValidationDlg = function () {
        var _this = this;
        var l10n = this.parent.serviceLocator.getService(locale);
        var dialogInst = this.parent.serviceLocator.getService(dialog);
        var skip = false;
        var noClick = false;
        var dlg = {
            width: 550, isModal: true, showCloseIcon: true, cssClass: 'e-goto-dlg',
            header: l10n.getConstant('Spreadsheet'),
            beforeOpen: function (args) {
                var dlgArgs = {
                    dialogName: 'ExtendValidation',
                    element: args.element, target: args.target, cancel: args.cancel
                };
                _this.parent.trigger('dialogBeforeOpen', dlgArgs);
                if (dlgArgs.cancel) {
                    args.cancel = true;
                }
                dialogInst.dialogInstance.content = l10n.getConstant('ExtendValidation');
                dialogInst.dialogInstance.dataBind();
                focus(_this.parent.element);
            },
            buttons: [{
                    buttonModel: {
                        content: l10n.getConstant('Yes'), isPrimary: true, cssClass: 'e-btn-goto-ok'
                    },
                    click: function () {
                        dialogInst.hide();
                        skip = true;
                    }
                },
                {
                    buttonModel: {
                        content: l10n.getConstant('No'), isPrimary: true, cssClass: 'e-btn-goto-ok'
                    },
                    click: function () {
                        dialogInst.hide();
                        skip = true;
                        noClick = true;
                    }
                }], close: function () {
                if (skip) {
                    _this.initiateDataValidationHandler(true, noClick);
                    skip = false;
                }
                else {
                    dialogInst.hide();
                }
            }
        };
        dialogInst.show(dlg);
    };
    DataValidation.prototype.userInput = function () {
        var listObj = this.typeList;
        var listObj1 = this.dataList;
        var dlgEle = this.parent.element.querySelector('.e-datavalidation-dlg');
        var dlgCont = dlgEle.querySelector('.e-validation-dlg');
        var allowDataCont = dlgCont.querySelector('.e-allowdata');
        var valuesCont = dlgCont.querySelector('.e-values');
        var l10n = this.parent.serviceLocator.getService(locale);
        var dataCont = allowDataCont.querySelector('.e-data');
        while (valuesCont.lastChild) {
            valuesCont.removeChild(valuesCont.lastChild);
        }
        if (listObj.value === l10n.getConstant('List')) {
            while (dataCont.lastChild) {
                dataCont.removeChild(dataCont.lastChild);
            }
            var ignoreBlankEle = this.parent.createElement('input', { className: 'e-checkbox' });
            this.inputElements.push(ignoreBlankEle);
            dataCont.appendChild(ignoreBlankEle);
            var ignoreBlankObj = new CheckBox({ label: l10n.getConstant('InCellDropDown'), checked: true });
            this.checkBoxElements.push(ignoreBlankObj);
            ignoreBlankObj.appendTo(ignoreBlankEle);
        }
        else {
            if (dataCont.getElementsByClassName('e-checkbox-wrapper')[0]) {
                while (dataCont.lastChild) {
                    dataCont.removeChild(dataCont.lastChild);
                }
                var dataText = this.parent.createElement('span', { className: 'e-header' });
                dataText.innerText = l10n.getConstant('Data');
                var dataSelectEle = this.parent.createElement('input', { className: 'e-select' });
                this.spanElements.push(dataText);
                this.inputElements.push(dataSelectEle);
                dataCont.appendChild(dataText);
                dataCont.appendChild(dataSelectEle);
                listObj1.appendTo(dataSelectEle);
            }
        }
        if (listObj.value !== l10n.getConstant('List') && (listObj1.value === l10n.getConstant('Between') || listObj1.value === l10n.getConstant('NotBetween'))) {
            var minimumCont = this.parent.createElement('div', { className: 'e-minimum' });
            var maximumCont = this.parent.createElement('div', { className: 'e-maximum' });
            this.divElements.push(minimumCont);
            this.divElements.push(maximumCont);
            valuesCont.appendChild(minimumCont);
            valuesCont.appendChild(maximumCont);
            var minimumText = this.parent.createElement('span', { className: 'e-header' });
            minimumText.innerText = l10n.getConstant('Minimum');
            var maximumText = this.parent.createElement('span', { className: 'e-header' });
            maximumText.innerText = l10n.getConstant('Maximum');
            var minimumInp = this.parent.createElement('input', { id: 'min', className: 'e-input', attrs: { value: '0' } });
            var maximumInp = this.parent.createElement('input', { id: 'max', className: 'e-input', attrs: { value: '0' } });
            this.spanElements.push(minimumText);
            this.spanElements.push(maximumText);
            this.inputElements.push(minimumInp);
            this.inputElements.push(maximumInp);
            var numericMin = new NumericTextBox({
                value: 0
            });
            this.numericTextBoxElements.push(numericMin);
            numericMin.appendTo('min');
            var numericMax = new NumericTextBox({
                value: 0
            });
            this.numericTextBoxElements.push(numericMax);
            numericMax.appendTo('max');
            minimumCont.appendChild(minimumText);
            minimumCont.appendChild(minimumInp);
            maximumCont.appendChild(maximumText);
            maximumCont.appendChild(maximumInp);
        }
        else {
            var valueText = this.parent.createElement('span', { className: 'e-header' });
            valueText.innerText = listObj.value === l10n.getConstant('List') ? l10n.getConstant('Sources') : l10n.getConstant('Value');
            var valueEle = listObj.value === l10n.getConstant('List') ? this.parent.createElement('input', {
                className: 'e-input',
                attrs: { placeholder: 'Enter value' }
            }) :
                this.parent.createElement('input', { className: 'e-input', attrs: { value: '0' } });
            this.spanElements.push(valueText);
            this.inputElements.push(valueEle);
            valuesCont.appendChild(valueText);
            valuesCont.appendChild(valueEle);
        }
    };
    DataValidation.prototype.dlgClickHandler = function (dialogInst) {
        var _this = this;
        var l10n = this.parent.serviceLocator.getService(locale);
        var errorMsg;
        var dlgEle = this.parent.element.querySelector('.e-datavalidation-dlg');
        var dlgFooter = dlgEle.querySelector('.e-footer-content');
        var dlgContEle = dlgEle.getElementsByClassName('e-dlg-content')[0].
            getElementsByClassName('e-validation-dlg')[0];
        var allowData = dlgContEle.getElementsByClassName('e-allowdata')[0];
        var allowEle = allowData.getElementsByClassName('e-allow')[0].getElementsByTagName('input')[0];
        var dataEle = allowData.getElementsByClassName('e-data')[0].getElementsByTagName('input')[0];
        var values = dlgContEle.getElementsByClassName('e-values')[0];
        var valueArr = [];
        valueArr[0] = values.getElementsByTagName('input')[0].value;
        valueArr[1] = values.getElementsByTagName('input')[1] ? values.getElementsByTagName('input')[1].value : '';
        var type = this.formattedType(allowEle.value);
        var isValid = true;
        var numObj = getNumericObject(this.parent.locale);
        if (type === 'Decimal' && numObj.decimal !== '.') {
            var isNotCulturedNumber = function (val) { return isNumber(val) && val.includes('.') &&
                (numObj.group !== '.' || !parseThousandSeparator(val, _this.parent.locale, numObj.group, numObj.decimal)); };
            if (isNotCulturedNumber(valueArr[0]) || isNotCulturedNumber(valueArr[1])) {
                isValid = false;
                errorMsg = l10n.getConstant('InvalidNumberError');
            }
        }
        parseLocaleNumber(valueArr, this.parent, numObj);
        var ignoreBlank = dlgContEle.querySelector('.e-ignoreblank .e-checkbox').checked;
        var inCellDropDown = allowData.querySelector('.e-data').querySelector('.e-checkbox-wrapper') ?
            allowData.querySelector('.e-data').querySelector('.e-checkbox-wrapper').querySelector('.e-check') ? true : false : null;
        var range = dlgContEle.querySelector('.e-cellrange').getElementsByTagName('input')[0].value;
        var operator;
        if (dataEle) {
            operator = dataEle.value;
            operator = this.formattedValue(operator);
        }
        var valArr = [];
        if (valueArr[0] !== '') {
            valArr.push(valueArr[0]);
        }
        if (valueArr[1] !== '') {
            valArr.push(valueArr[1]);
        }
        if (type === 'List') {
            if (valueArr[0].startsWith('=')) {
                var address = valueArr[0].substring(1);
                if (address.includes(':')) {
                    var isSheetNameValid = void 0;
                    if (valueArr[0].includes('!')) {
                        var refArr = address.split('!');
                        address = refArr[1];
                        if (refArr[0].startsWith('\'') && refArr[0].endsWith('\'')) {
                            refArr[0] = refArr[0].substring(1, refArr[0].length - 1);
                        }
                        isSheetNameValid = getSheetIndex(this.parent, refArr[0]) > -1;
                        valArr[0] = "=" + refArr.join('!');
                    }
                    else {
                        isSheetNameValid = true;
                    }
                    var cellRef = address.split(':');
                    var isSingleCol = address.match(/[a-z]/gi) &&
                        cellRef[0].replace(/[0-9]/g, '') === cellRef[1].replace(/[0-9]/g, '');
                    var isSingleRow = address.match(/\d/g) && cellRef[0].replace(/\D/g, '') === cellRef[1].replace(/\D/g, '');
                    isValid = isSheetNameValid && (isSingleCol || isSingleRow);
                    if (!isValid) {
                        errorMsg = l10n.getConstant('DialogError');
                    }
                }
            }
            else if (valueArr[0].length > 256) {
                isValid = false;
                errorMsg = l10n.getConstant('ListLengthError');
            }
        }
        if (isValid) {
            var sheet = this.parent.getActiveSheet();
            var validDlg = this.isDialogValidator(valArr, type, operator);
            if (operator === 'Between' && validDlg.isValidate && !isNaN(parseFloat(valArr[0])) && !isNaN(parseFloat(valArr[1])) &&
                parseFloat(valArr[0]) > parseFloat(valArr[1])) {
                validDlg.isValidate = false;
                validDlg.errorMsg = l10n.getConstant('MinMaxError');
            }
            errorMsg = validDlg.errorMsg;
            isValid = validDlg.isValidate;
            if (isValid) {
                var args = { range: sheet.name + '!' + range, value1: valArr[0], value2: valArr[1] || '',
                    ignoreBlank: ignoreBlank, type: type, operator: operator, inCellDropDown: inCellDropDown, cancel: false };
                this.parent.notify(beginAction, { eventArgs: args, action: 'validation' });
                if (!args.cancel) {
                    this.parent.notify(cellValidation, { rules: { type: args.type, operator: args.operator, value1: args.value1, value2: args.value2,
                            ignoreBlank: args.ignoreBlank, inCellDropDown: args.inCellDropDown }, range: args.range });
                    delete args.cancel;
                    if (!document.getElementsByClassName('e-validation-error-dlg')[0]) {
                        if (dialogInst.dialogInstance) {
                            dialogInst.dialogInstance.hide();
                        }
                        else {
                            dialogInst.hide();
                        }
                    }
                    this.parent.notify(completeAction, { eventArgs: args, action: 'validation' });
                }
            }
        }
        if (!isValid) {
            var errorEle = this.parent.createElement('div', { className: 'e-dlg-error', id: 'e-invalid' });
            errorEle.innerText = errorMsg;
            values.appendChild(errorEle);
            dlgFooter.querySelector('.e-primary').setAttribute('disabled', 'true');
        }
    };
    DataValidation.prototype.formattedValue = function (value) {
        var l10n = this.parent.serviceLocator.getService(locale);
        switch (value) {
            case l10n.getConstant('Between'):
                value = 'Between';
                break;
            case l10n.getConstant('NotBetween'):
                value = 'NotBetween';
                break;
            case l10n.getConstant('EqualTo'):
                value = 'EqualTo';
                break;
            case l10n.getConstant('NotEqualTo'):
                value = 'NotEqualTo';
                break;
            case l10n.getConstant('GreaterThan'):
                value = 'GreaterThan';
                break;
            case l10n.getConstant('LessThan'):
                value = 'LessThan';
                break;
            case l10n.getConstant('GreaterThanOrEqualTo'):
                value = 'GreaterThanOrEqualTo';
                break;
            case l10n.getConstant('LessThanOrEqualTo'):
                value = 'LessThanOrEqualTo';
                break;
            default:
                value = 'Between';
                break;
        }
        return value;
    };
    DataValidation.prototype.formattedType = function (value) {
        var l10n = this.parent.serviceLocator.getService(locale);
        switch (value) {
            case l10n.getConstant('WholeNumber'):
                value = 'WholeNumber';
                break;
            case l10n.getConstant('Decimal'):
                value = 'Decimal';
                break;
            case l10n.getConstant('Date'):
                value = 'Date';
                break;
            case l10n.getConstant('TextLength'):
                value = 'TextLength';
                break;
            case l10n.getConstant('List'):
                value = 'List';
                break;
            case l10n.getConstant('Time'):
                value = 'Time';
                break;
            default:
                break;
        }
        return value;
    };
    DataValidation.prototype.isDialogValidator = function (values, type, operator) {
        var l10n = this.parent.serviceLocator.getService(locale);
        var count = 0;
        var isEmpty = false;
        var formValidation;
        if (type === 'List') {
            isEmpty = values.length > 0 ? false : true;
        }
        else {
            if (operator === 'Between' || operator === 'NotBetween') {
                isEmpty = values.length === 2 ? false : true;
            }
            else {
                isEmpty = values.length > 0 ? false : true;
            }
        }
        if (!isEmpty) {
            var value = void 0;
            for (var idx = 0; idx < values.length; idx++) {
                if (checkIsFormula(values[idx])) {
                    value = this.parent.computeExpression(values[idx]).toString();
                }
                else if (type === 'Date' || type === 'Time') {
                    value = values[idx] = this.getDateAsNumber({ range: [], cell: { value: values[idx] } }, values[idx]);
                }
                else {
                    value = values[idx];
                }
                formValidation = this.formatValidation(value, type, true);
                if (formValidation.isValidate) {
                    count = count + 1;
                }
                else {
                    break;
                }
            }
            formValidation.isValidate = count === values.length ? true : false;
            return formValidation;
        }
        else {
            return { isValidate: false, errorMsg: l10n.getConstant('EmptyError') };
        }
    };
    DataValidation.prototype.getDateAsNumber = function (args, cellValue) {
        var dateEventArgs = { value: cellValue, rowIndex: args.range[0], cell: args.cell,
            colIndex: args.range[1], sheetIndex: args.sheetIdx, updatedVal: '' };
        this.parent.notify(checkDateFormat, dateEventArgs);
        return dateEventArgs.updatedVal || cellValue;
    };
    DataValidation.prototype.getListOfValues = function (listValue) {
        var listValArr;
        if (this.parent.listSeparator !== ',' && listValue.includes(this.parent.listSeparator)) {
            listValArr = listValue.split(this.parent.listSeparator);
        }
        else {
            listValArr = listValue.split(',');
        }
        return listValArr;
    };
    DataValidation.prototype.isValidationHandler = function (args) {
        var l10n = this.parent.serviceLocator.getService(locale);
        args.value = isNullOrUndefined(args.value) ? '' : args.value;
        var isValidate;
        var errorMsg;
        var enterValue = args.value.toString();
        var sheet = this.parent.sheets[args.sheetIdx];
        var validation;
        var cell = getCell(args.range[0], args.range[1], sheet);
        var column = getColumn(sheet, args.range[1]);
        if (cell && cell.validation) {
            validation = cell.validation;
        }
        else if (checkColumnValidation(column, args.range[0], args.range[1])) {
            validation = column.validation;
        }
        if (validation) {
            var value1 = validation.value1;
            var value2 = validation.value2;
            if (validation.type !== 'List') {
                if (checkIsFormula(value1)) {
                    value1 = this.parent.computeExpression(value1).toString();
                }
                if (checkIsFormula(value2)) {
                    value2 = this.parent.computeExpression(value2).toString();
                }
                if (checkIsFormula(args.value)) {
                    args.value = this.parent.computeExpression(args.value).toString();
                }
            }
            var value = args.value;
            var opt = validation.operator || 'Between';
            var type = validation.type || 'WholeNumber';
            var ignoreBlank = isNullOrUndefined(validation.ignoreBlank) ? true : validation.ignoreBlank;
            if (ignoreBlank && enterValue === '') {
                isValidate = true;
            }
            else {
                var isDateTimeType = type === 'Date' || type === 'Time';
                if (args.value) {
                    if (isDateTimeType || validation.type === 'TextLength') {
                        if (!isNumber(args.value)) {
                            value = args.value = this.getDateAsNumber(args, args.value);
                        }
                    }
                    else {
                        var numObj = args.isEdit && getNumericObject(this.parent.locale);
                        var numVal = this.parseValidationValue(args.value, numObj);
                        if (numVal !== args.value && isNumber(numVal)) {
                            value = args.value = numVal.toString();
                        }
                    }
                }
                var formValidation = this.formatValidation(args.value, type);
                isValidate = formValidation.isValidate;
                errorMsg = formValidation.errorMsg;
                if (isValidate) {
                    isValidate = false;
                    if (isDateTimeType) {
                        if (value1 && !isNumber(value1)) {
                            value1 = this.getDateAsNumber(args, value1);
                        }
                        if (value2 && !isNumber(value2)) {
                            value2 = this.getDateAsNumber(args, value2);
                        }
                    }
                    else if (validation.type === 'TextLength') {
                        value = args.value.toString().length.toString();
                    }
                    if (type === 'List') {
                        var val = args.value.toString();
                        var isNumVal = isNumber(val);
                        var numObj = isNumVal && getNumericObject(this.parent.locale);
                        if (value1.indexOf('=') !== -1) {
                            var listVal = void 0;
                            var data = this.getListDataSource(validation);
                            for (var idx = 0; idx < data.length; idx++) {
                                listVal = data[idx].text.toString();
                                if (enterValue === listVal || val === listVal || (isNumVal &&
                                    val === this.parseValidationValue(listVal, numObj).toString())) {
                                    isValidate = true;
                                    break;
                                }
                            }
                        }
                        else {
                            var listValues = this.getListOfValues(value1);
                            for (var idx = 0; idx < listValues.length; idx++) {
                                if (enterValue === listValues[idx] || val === listValues[idx] || (isNumVal &&
                                    val === this.parseValidationValue(listValues[idx], numObj).toString())) {
                                    isValidate = true;
                                    break;
                                }
                            }
                        }
                        if (!isValidate && ignoreBlank && val === '') {
                            isValidate = true;
                        }
                    }
                    else {
                        if (type === 'Decimal' || type === 'Time') {
                            value = parseFloat(value.toString());
                            value1 = parseFloat(value1.toString());
                            value2 = value2 ? parseFloat(value2.toString()) : null;
                        }
                        else {
                            value = parseInt(value.toString(), 10);
                            value1 = parseInt(value1.toString(), 10);
                            value2 = value2 ? parseInt(value2.toString(), 10) : null;
                        }
                        switch (opt) {
                            case 'EqualTo':
                                if (value === value1) {
                                    isValidate = true;
                                }
                                else if (ignoreBlank && enterValue === '') {
                                    isValidate = true;
                                }
                                else {
                                    isValidate = false;
                                }
                                break;
                            case 'NotEqualTo':
                                if (value !== value1) {
                                    isValidate = true;
                                }
                                else if (ignoreBlank && enterValue === '') {
                                    isValidate = true;
                                }
                                else {
                                    isValidate = false;
                                }
                                break;
                            case 'Between':
                                if (value >= value1 && value <= value2) {
                                    isValidate = true;
                                }
                                else if (ignoreBlank && enterValue === '') {
                                    isValidate = true;
                                }
                                else {
                                    isValidate = false;
                                }
                                break;
                            case 'NotBetween':
                                if (value >= value1 && value <= value2) {
                                    isValidate = false;
                                }
                                else if (ignoreBlank && enterValue === '') {
                                    isValidate = true;
                                }
                                else {
                                    isValidate = true;
                                }
                                break;
                            case 'GreaterThan':
                                if (value > value1) {
                                    isValidate = true;
                                }
                                else if (ignoreBlank && enterValue === '') {
                                    isValidate = true;
                                }
                                else {
                                    isValidate = false;
                                }
                                break;
                            case 'LessThan':
                                if (value < value1) {
                                    isValidate = true;
                                }
                                else if (ignoreBlank && enterValue === '') {
                                    isValidate = true;
                                }
                                else {
                                    isValidate = false;
                                }
                                break;
                            case 'GreaterThanOrEqualTo':
                                if (value >= value1) {
                                    isValidate = true;
                                }
                                else if (ignoreBlank && enterValue === '') {
                                    isValidate = true;
                                }
                                else {
                                    isValidate = false;
                                }
                                break;
                            case 'LessThanOrEqualTo':
                                if (value <= value1) {
                                    isValidate = true;
                                }
                                else if (ignoreBlank && enterValue === '') {
                                    isValidate = true;
                                }
                                else {
                                    isValidate = false;
                                }
                                break;
                            default:
                                break;
                        }
                    }
                }
            }
        }
        errorMsg = l10n.getConstant('ValidationError');
        if (isValidate && ((cell && cell.validation && cell.validation.isHighlighted) ||
            (column && column.validation && column.validation.isHighlighted))) {
            var style = this.parent.getCellStyleValue(['backgroundColor', 'color'], [args.range[0], args.range[1]]);
            if (!isHiddenRow(sheet, args.range[0])) {
                this.parent.notify(applyCellFormat, { style: style, rowIdx: args.range[0], colIdx: args.range[1],
                    isHeightCheckNeeded: true, manualUpdate: true, onActionUpdate: true, td: args.td });
            }
        }
        return { isValidate: isValidate, errorMsg: errorMsg };
    };
    DataValidation.prototype.parseValidationValue = function (val, numObj) {
        if (isNumber(val)) {
            if (numObj && numObj.group === '.') {
                val = val.toString();
                if (val.indexOf('.') && parseThousandSeparator(val, this.parent.locale, numObj.group, numObj.decimal)) {
                    val = val.split(numObj.group).join('');
                }
            }
            return val;
        }
        var formatArgs = { formattedText: val, value: val, format: 'General', cell: { value: val, format: 'General' },
            isEdit: !!numObj };
        this.parent.notify(getFormattedCellObject, formatArgs);
        return formatArgs.value;
    };
    DataValidation.prototype.checkDataValidation = function (args) {
        var cell = getCell(args.range[0], args.range[1], this.parent.getActiveSheet());
        args.td = args.td || this.parent.getCell(args.range[0], args.range[1]);
        args.sheetIdx = args.sheetIdx || this.parent.activeSheetIndex;
        var formulaArgs = { skip: false, value: '' };
        if (cell && cell.validation) {
            if (checkIsFormula(cell.validation.value1) &&
                !isCellReference(cell.validation.value1.substring(1, cell.validation.value1.length)) &&
                cell.validation.value1.indexOf('(') > -1) {
                var val = cell.validation.value1;
                val = val.substring(val.indexOf('=') + 1, val.indexOf('('));
                formulaArgs.value = val.toUpperCase();
                this.parent.notify(formulaInValidation, formulaArgs);
            }
            if (!formulaArgs.skip && checkIsFormula(cell.validation.value2) &&
                !isCellReference(cell.validation.value2.substring(1, cell.validation.value2.length)) &&
                cell.validation.value1.indexOf('(') > -1) {
                var val2 = cell.validation.value2;
                val2 = val2.substring(val2.indexOf('=') + 1, val2.indexOf('('));
                formulaArgs.value = val2.toUpperCase();
                this.parent.notify(formulaInValidation, formulaArgs);
            }
        }
        if (!formulaArgs.skip) {
            var isValid = this.isValidationHandler(args);
            if (!isValid.isValidate && args.isEdit) {
                this.validationErrorHandler(isValid.errorMsg);
            }
            args.isValid = isValid.isValidate;
        }
    };
    DataValidation.prototype.formatValidation = function (value, type, isDialogValidator) {
        var sheetPanel = this.parent.element.getElementsByClassName('e-sheet-panel')[0];
        var errorMsg;
        var formEle = this.parent.createElement('form', { id: 'formId', className: 'form-horizontal' });
        var inputEle = this.parent.createElement('input', { id: 'e-validation' });
        inputEle.setAttribute('name', 'validation');
        inputEle.setAttribute('type', 'text');
        if (type === 'Date' && isNumber(value)) {
            var valArr = value.toString().split('.');
            if (valArr.length === 2) {
                value = valArr[0];
            }
        }
        inputEle.setAttribute('value', value);
        formEle.appendChild(inputEle);
        sheetPanel.appendChild(formEle);
        var options;
        switch (type) {
            case 'Date':
                options = {
                    rules: {
                        'validation': { date: true }
                    },
                    customPlacement: function (inputElement, error) {
                        errorMsg = error.innerText;
                    }
                };
                break;
            case 'Decimal':
                options = {
                    rules: {
                        'validation': { number: true }
                    },
                    customPlacement: function (inputElement, error) {
                        errorMsg = error.innerText;
                    }
                };
                break;
            case 'WholeNumber':
                options = {
                    rules: {
                        'validation': { regex: /^-?\d*\.?[0]*$/ }
                    },
                    customPlacement: function (inputElement, error) {
                        errorMsg = error.innerText;
                    }
                };
                break;
            case 'TextLength':
                if (isDialogValidator) {
                    options = {
                        rules: {
                            'validation': { regex: /^\d*\.?[0]*$/ }
                        },
                        customPlacement: function (inputElement, error) {
                            errorMsg = error.innerText;
                        }
                    };
                }
                break;
            default:
                break;
        }
        this.formObj = new FormValidator('#formId', options);
        var isValidate = this.formObj.validate();
        sheetPanel.removeChild(sheetPanel.getElementsByClassName('form-horizontal')[0]);
        return { isValidate: isValidate, errorMsg: errorMsg };
    };
    DataValidation.prototype.invalidElementHandler = function (args) {
        var rowIdx = args.rowIdx;
        var colIdx = args.colIdx;
        var isRemoveHighlightedData = args.isRemoveHighlightedData;
        if (!isRemoveHighlightedData) {
            this.parent.notify(applyCellFormat, {
                style: { backgroundColor: '#ffff00', color: '#ff0000' }, rowIdx: rowIdx, colIdx: colIdx, td: args.td
            });
        }
        else if (isRemoveHighlightedData) {
            var style = this.parent.getCellStyleValue(['backgroundColor', 'color'], [rowIdx, colIdx]);
            this.parent.notify(applyCellFormat, {
                style: style, rowIdx: rowIdx, colIdx: colIdx, td: args.td
            });
        }
    };
    DataValidation.prototype.validationErrorHandler = function (error) {
        var _this = this;
        var el = document.getElementsByClassName('e-spreadsheet-edit')[0];
        var l10n = this.parent.serviceLocator.getService(locale);
        if (!this.parent.element.querySelector('.e-validation-error-dlg')) {
            var erroDialogInst_1 = this.parent.serviceLocator.getService(dialog);
            var disableCancel = false;
            var dlgModel = {
                width: 400, height: 200, isModal: true, showCloseIcon: true, cssClass: 'e-validation-error-dlg',
                target: document.querySelector('.e-control.e-spreadsheet'),
                beforeOpen: function (args) {
                    var dlgArgs = {
                        dialogName: 'ValidationErrorDialog',
                        element: args.element, target: args.target, cancel: args.cancel, content: error
                    };
                    _this.parent.trigger('dialogBeforeOpen', dlgArgs);
                    if (dlgArgs.cancel) {
                        _this.errorDlgHandler(erroDialogInst_1, 'Cancel');
                        args.cancel = true;
                    }
                    el.focus();
                    erroDialogInst_1.dialogInstance.content = dlgArgs.content;
                    erroDialogInst_1.dialogInstance.dataBind();
                },
                buttons: [{
                        buttonModel: {
                            content: l10n.getConstant('Retry'), isPrimary: true
                        },
                        click: function () {
                            _this.errorDlgHandler(erroDialogInst_1, 'Retry');
                        }
                    },
                    {
                        buttonModel: {
                            content: l10n.getConstant('Cancel')
                        },
                        click: function () {
                            _this.errorDlgHandler(erroDialogInst_1, 'Cancel');
                        }
                    }]
            };
            erroDialogInst_1.show(dlgModel, disableCancel);
        }
    };
    DataValidation.prototype.errorDlgHandler = function (errorDialogInst, buttonName) {
        if (buttonName === 'Retry') {
            var el = document.getElementsByClassName('e-spreadsheet-edit')[0];
            errorDialogInst.hide();
            if (el.innerText) {
                window.getSelection().selectAllChildren(el);
                if (this.listObj && !this.listObj.isDestroyed) {
                    this.listObj.showPopup();
                }
            }
        }
        else {
            var indexes = getCellIndexes(this.parent.getActiveSheet().activeCell);
            var cell = getCell(indexes[0], indexes[1], this.parent.getActiveSheet());
            var value = cell ? this.parent.getDisplayText(cell) : '';
            this.parent.notify(editOperation, {
                action: 'cancelEdit', value: value, refreshFormulaBar: true,
                refreshEditorElem: true, isAppend: false, trigEvent: true
            });
            errorDialogInst.hide();
        }
    };
    /**
     * Gets the module name.
     *
     * @returns {string} - Gets the module name.
     */
    DataValidation.prototype.getModuleName = function () {
        return 'dataValidation';
    };
    return DataValidation;
}());
export { DataValidation };
